import React, { useState } from 'react';

import ItemsCarousel from 'react-items-carousel';

import flagbrazil from '../../assets/brazil-flag-medium.png';
import flagunitedstates from '../../assets/united-states-of-america-flag-medium.png';
import androidlogo from '../../assets/android-logo1.png';
import logojava from '../../assets/LogoJava.png';
import dialogflowlogo from '../../assets/chatbot1-transparent.png';
import computerclogo from '../../assets/c-4.png';
import logoreact from '../../assets/LogoReact.png';
import logomaterialize from '../../assets/n9qdpfw4kwsjqox0lymi.png';
import logomongodb from '../../assets/mongodb-logo.png';
import logographql from '../../assets/graphql.png';
import logoreactnative from '../../assets/React-NAtive.png';
import logonode from '../../assets/node-js-736399_960_720.png';
import logokoin from '../../assets/oie_DfoefhEory1A.png';

import {
  Container,
  HeaderContainer,
  AboutContainer,
  FooterContainer,
  IconContainer,
  SocialIconContainer,
  IconImage,
  Title,
  DescriptionText,
  AboutText,
  WaitText,
  FooterText
} from './styles';

const techonolgyImages = [
  {
    name: 'Android',
    image: androidlogo
  },
  {
    name: 'DialogFlow',
    image: dialogflowlogo
  },
  {
    name: 'Java',
    image: logojava
  },
  {
    name: 'C#',
    image: computerclogo
  },
  {
    name: 'React',
    image: logoreact
  },
  {
    name: 'React Native',
    image: logoreactnative
  },
  {
    name: 'Materialize CSS',
    image: logomaterialize
  },
  {
    name: 'MongoDB',
    image: logomongodb
  },
  {
    name: 'GraphQL',
    image: logographql
  },
  {
    name: 'Node JS',
    image: logonode
  },
  /* {
    name: 'Koin',
    image: logokoin
  } */
]

export default function Main() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <Container>
      <HeaderContainer>
        {/* <IconContainer>
          <IconImage src={flagbrazil} />
          <IconImage src={flagunitedstates} />
        </IconContainer> */}
      </HeaderContainer>
      <AboutContainer>
        <Title>I am Matheus Tadeu</Title>
        <AboutText>
          A Software Enginner specialized in Mobile Development
        </AboutText>
        <DescriptionText>
          And I am building something new for my Portfolio
        </DescriptionText>
        <WaitText>Please wait for this amazing thing</WaitText>
        <SocialIconContainer>
          <a
            href="https://www.facebook.com/matheus.tadeu.71"
            class="btn-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i class="fa fa-facebook-square fa-lg" aria-hidden="true"></i>{" "}
          </a>
          <a
            href="https://www.linkedin.com/in/matheus-tadeu/"
            class="btn-linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i class="fa fa-linkedin-square fa-lg" aria-hidden="true"></i>{" "}
          </a>
          <a
            href="https://github.com/tadeumx1"
            class="btn-github"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <i class="fa fa-github-square fa-lg" aria-hidden="true"></i>{" "}
          </a>
          <a
            href="https://wakatime.com/@tadeumx1"
            class="btn1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-clock-o fa-lg" aria-hidden="true"></i>{" "}
          </a>
          <a
            href="https://instagram.com/tadeumx/"
            class="btn1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>{" "}
          </a>
        </SocialIconContainer>
        <FooterText>You can acess my old portfolio <a href='https://matheustadeu.com/tadeumx1-old-portfolio/'><span>clicking here</span></a></FooterText>
      </AboutContainer>
      {/* No Footer colocar um Carousel com as tecnologias */}
      <FooterContainer>
        <h1 className='title-carousel'>I always use amazing technologies</h1>
        <div className='carousel' style={{ padding: "0 20px", maxWidth: 500, margin: "0 auto" }}>
          <ItemsCarousel
            infiniteLoop={true}
            gutter={100}
            activePosition={"center"}
            chevronWidth={30}
            disableSwipe={false}
            alwaysShowChevrons={false}
            numberOfCards={4}
            slidesToScroll={4}
            outsideChevron={true}
            showSlither={false}
            firstAndLastGutter={false}
            activeItemIndex={activeItemIndex}
            requestToChangeActive={setActiveItemIndex}
            rightChevron={<button>{">"}</button>}
            leftChevron={<button>{"<"}</button>}
          >
            {techonolgyImages.map(item => {
             if(item.name === 'React Native') {
                return (
                  <div style={{ height: 94, display: 'flex', alignItems: 'center' }}>
                    <img style={{ width: 90 }} src={item.image} alt={item.name} />
                  </div>
                )
              }

              if(item.name === 'React') {
                return (
                  <div style={{ height: 94, display: 'flex', alignItems: 'center' }}>
                    <img style={{ height: 90, width: 90 }} src={item.image} alt={item.name} />
                  </div>
                )
              }

              if(item.name === 'Node JS') {
                return (
                  <div style={{ height: 94, display: 'flex', alignItems: 'center' }}>
                    <img style={{ height: 68, width: 100 }} src={item.image} alt={item.name} />
                  </div>
                )
              }

              if(item.name === 'Koin') {
                return (
                  <div style={{ height: 94, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <img style={{ height: 68, width: 100 }} src={item.image} alt={item.name} />
                  </div>
                )
              }

              if(item.name === 'C#') {
                return (
                  <div style={{ height: 94, display: 'flex', alignItems: 'center' }}>
                    <img style={{ height: 80, width: 90 }} src={item.image} alt={item.name} />
                  </div>
                )
              }
              
              return <img style={{ height: 90, width: 100 }} src={item.image} alt={item.name} />
            })}
          </ItemsCarousel>
        </div>
      </FooterContainer>
    </Container>
  );
}
