import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #F6F6F6;
  padding: 10px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #F6F6F6;
`;

export const AboutContainer = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
`;

export const FooterContainer = styled.div`
  flex: 1;
  background-color: #F6F6F6;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Roboto';
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  /* @media (max-width: 400px) {
    .title-carousel {
      display: none;
    }

    .carousel {
      display: none;
    }
  } */

`;

export const IconContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 5px;
`;

export const IconImage = styled.img`
  height: 30px;
  width: 48px;
  margin: 0 5px;
`;

export const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  a {
    margin: 0 10px;
  }

`;

export const Title = styled.h1`
  font-family: 'Roboto';
  font-size: 50px;
  font-weight: bold;
  margin: 20px 0px;
  text-align: center;

  @media (max-width: 700px) {
   font-size: 30px;
  }

`;

export const DescriptionText = styled.h3`
  font-family: 'Roboto';
  font-size: 30px;
  text-align: center;
  margin: 30px 0px;

  @media (max-width: 700px) {
   font-size: 20px;
  }
`;

export const AboutText = styled.h3`
  font-family: 'Roboto';
  font-size: 35px;
  text-align: center;
  margin: 10px 0px;

  @media (max-width: 700px) {
   font-size: 20px;
  }
`;

export const WaitText = styled.h4`
  font-family: 'Roboto';
  font-size: 20px;
  text-align: center;
  margin: 10px 0px;

  @media (max-width: 700px) {
   font-size: 16px;
  }
`;

export const FooterText = styled.h5`
  font-family: 'Roboto';
  font-size: 20px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
   font-size: 16px;
  }

  span {
    color: #686868;
    text-decoration: underline;
  }
`;

